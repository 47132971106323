.pagination-container .pagination > li a {
    padding: 2px 10px;
}
.pagination-container .pagination > li {
    color: #fff;
    text-decoration: none;
    margin: 0 4px;
    border-radius: 2px;
    font-weight: 600;
    cursor: pointer;
}
.pagination-container .pagination li.selected, .pagination-container .pagination li:not(.arrow, .break):hover{
    background-image: linear-gradient(#517bcd, #3c60a8);
}

/*
.pagination > .arrow {
    color: #fff;
    text-decoration: none;
    margin: 0 4px;
    border-radius: 2px;
    font-weight: 600;
    cursor: pointer;
}*/
