.analytics-searchbar-component .autocomplete-users{
    top: 37px!important;
    padding-bottom: unset;;
}



.hidden-span {
    position: absolute;
    visibility: hidden;
    white-space: pre;
    font-family: inherit; /* Ensure it matches the input's font */
    font-size: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
}


.analytics-searchbar-component .search-bar-form {
    max-width: none !important;
    min-width: 240px !important;
}
@media screen and (max-width: 480px) {
    .analytics-searchbar-component .search-bar-form .text-field-2{
        max-width: unset;
        min-width: unset!important;
        width: 100%!important;
    }
    .analytics-searchbar-component .search-bar-form{
        min-width: unset !important;
        max-width: unset !important;
        width:100%!important;
    }
    .analytics-searchbar-component .div-block-245{
        width: 100%;
    }
    .analytics-searchbar-component{
        width: 100%;
    }
}