.header-search-bar{
    position: relative;
}

.autocomplete-users {
    position: absolute;
    top: 37px;
    background: #2D303B;
    width: 100%;
    max-height: 309px;
    overflow-y: auto;
}
.user-row {
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    display: block;
    text-decoration: none;
    color: #fff;
}
.user-row:hover {
    background: #3D4156;
}
.af-view .nav .prof_menu_popup{
    width: auto;
}