/*
.react-select__menu{
    min-height: 300px;
}*/

.assign-license-popup .w-tab-content{
    overflow: unset!important;
}

.assign-license-popup .user-switcher {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 15px;
    margin-bottom: 0px!important;
}
/* Hide default HTML checkbox */
.assign-license-popup .user-switcher input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The user-switcher-slider */
.assign-license-popup .user-switcher-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .2s;
    transition: .2s;
}
.assign-license-popup .user-switcher-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -7px;
    bottom: -3px;
    background-color: #656565;
    -webkit-transition: .1s;
    transition: .1s;
}
.assign-license-popup .user-switcher input:not(:checked) + .user-switcher-slider img.right-img{
    display: none;
}
.assign-license-popup .user-switcher input:checked + .user-switcher-slider img.left-img{
    display: none;
}
.assign-license-popup .user-switcher input:checked + .user-switcher-slider:before{
    background-color: #527bcd;
}
.assign-license-popup .user-switcher-slider img{
    filter: brightness(0) invert(1) !important;
    width: 16px;
    height: 21px;
    position: absolute;
}
.assign-license-popup .user-switcher-slider img.left-img{
    left: 3px;
    bottom: 0px;
}
.assign-license-popup .user-switcher-slider img.right-img{
    right: 0px;
    bottom: -1px;
    width:16px;
}
.assign-license-popup .user-switcher input:checked + .user-switcher-slider {
    background-color: rgba(36, 103, 235, 0.6);
}
.assign-license-popup .user-switcher input:focus + .user-switcher-slider {
    box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
}
.assign-license-popup .user-switcher input:checked + .user-switcher-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
/* Rounded user-switcher-sliders */
.assign-license-popup .user-switcher-slider.round {
    border-radius: 15px;
}
.assign-license-popup .user-switcher-slider.round:before {
    border-radius: 50%;
}