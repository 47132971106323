.no-pathway-course-content{
    justify-content: center!important;
    min-height: 370px;
}
.popup_div-nm_popup.pathway-add-content-popup .popup_cont-sk .hf_popup.small{
    height: 100%;
    max-height: 80%;
}
.popup_div-nm_popup.pathway-add-content-popup .popup_cont-sk{
    display: flex;
    max-height: 80%;
    align-items: center;
    height: 100%;
}
.popup_div-nm_popup.pathway-add-content-popup .popup_cont-sk .div-block-228.pathway-content-block{
    height: calc(100% - 113px);
    overflow: auto;
}
.popup_div-nm_popup.pathway-add-content-popup .popup_cont-sk.h-100{
    height: 100%;
}
.j-content-space-btw{
    justify-content: space-between;
}

.select-field-14 { color: transparent; -webkit-text-fill-color: #FFF; }