.merlin.chat {
    display: none;
}


.circle .inner {
    background: #272c34 !important;
    color: #fff !important;
}

label.switch {
    margin-bottom: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 15px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch input:checked + .slider {
    background-color: rgba(82, 123, 205, 0.6);
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #525252;
    -webkit-transition: .4s;
    transition: .4s;
}

.switch input:checked + .slider:before {
    background-color: rgba(82, 123, 205, 1);
}

.switch input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

.switch .slider.round:before {
    border-radius: 50%;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 0px;
    bottom: -1px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.create-report-popup select {
    -webkit-appearance: listbox !important
}

.profile_menu .mCSB_container {
    min-width: 900px;
}

.profile_menu .mCustomScrollBox {
    width: 97%;
}

@media screen and (min-width: 1280px) {
    .sk1 .mCSB_container {
        width: auto !important;
        left: 0 !important;
    }
}

input, textarea {
    color: #fff !important;
}

body.locked {
    overflow: hidden !important;
}

.tp_content {
    visibility: hidden;
}

.submenu.clicked {
    display: block;
    padding: 0px;
    background-color: #383d48;
}

@media screen and (min-width: 991px) {
    .sidebar {
        left: 0 !important;
    }
}

.sidebar {
    transition: 0.5s;
}

iframe {
    border: none !important;
}

.l1.tr {
    flex-shrink: 0;
}

.nav {
    border-image: linear-gradient(90deg, #0A3B63, #527bcd) 3;
}

#myChart2 {
    height: 220px !important;
}

.menu-option.active {
    border-image: linear-gradient(180deg, #851168, #f75eef) 2;
}

.submenu-item.active:hover:not(.web) {
    border-image: linear-gradient(180deg, #fff, #fff) 2;
}

.submenu-item.active:not(.web):not(.web-sub) {
    border-image: linear-gradient(180deg, #fff, #fff) 2;
}

.submenu-item:hover:not(.active) {
    border-image: linear-gradient(180deg, #fff, #fff) 2;
}

.submenu-item:hover:not(.active) > a {
    color: #fff !important;
}

.link-block-13:hover:not(.active) {
    border-image: linear-gradient(180deg, #851168, #f75eef) 2;
}

.link-block-13:hover:not(.active) {
    color: #527bcd !important;
}

.link-block-13:hover:not(.active) .text-block-107 {
    color: #527bcd !important;
}

.link-block-13:hover:not(.active) .icon {
    display: none !important;
}

.link-block-13:hover:not(.active) .icon_h {
    display: inline !important;
}

.image-4 {
    box-shadow: 0 0 1px 1px #fff;
}

.submenu-item.active:not(.web):not(.web-sub) {
    border-image: linear-gradient(180deg, #851168, #f75eef) 2 !important;
}

.link-block-12.active {
    color: #527bcd !important;
}

.submenu-item.web-sub:hover {
    border-color: transparent !important;
    border-image: none !important;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4);
}

::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.5);
}

.submenu-item:hover:not(.active) > a {
    color: #527bcd !important;
}

.submenu-item:hover:not(.active):not(.web-sub) {
    border-image: linear-gradient(180deg, #851168, #f75eef) 2 !important;
}

.tp_content.sho {
    visibility: visible;
    display: none;
}

.tp_content {
    display: none;
}

@media screen and (max-width: 991px) {
    .real_map {
        height: 350px !important;
    }
}

.mob_nav {
    border-image: linear-gradient(90deg, #0A3B63, #527bcd) 3 !important;
}

.mob_nav .text-block-125 {
    display: none;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2) !important;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.4) !important;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.6) !important;
}

::-webkit-scrollbar-thumb:active {
    background: rgba(255, 255, 255, 0.8) !important;
}

.table_cont-2::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent !important;
}

.table_cont-2::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #527bcd !important;
}

.table_cont-2::-webkit-scrollbar-thumb:hover {
    background: #527bcd !important;
}

.table_cont-2::-webkit-scrollbar-thumb:active {
    background: #527bcd !important;
}

.div-block-183.w_i::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent !important;
}

.div-block-183.w_i::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #527bcd !important;
}

.div-block-183.w_i::-webkit-scrollbar-thumb:hover {
    background: #527bcd !important;
}

.div-block-183.w_i::-webkit-scrollbar-thumb:active {
    background: #527bcd !important;
}

.div-block-183.wr.sp::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent !important;
}

.div-block-183.wr.sp::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #527bcd !important;
}

.div-block-183.wr.sp::-webkit-scrollbar-thumb:hover {
    background: #527bcd !important;
}

.div-block-183.wr.sp::-webkit-scrollbar-thumb:active {
    background: #527bcd !important;
}

@media screen and (max-width: 410px) {
    .apexcharts-legend {
        bottom: -15% !important;
    }
}

select {
    -webkit-appearance: none;
}

.form-block-2 form {
    display: flex
}

.form-block-2 form:after {
    color: #9da5b4;
    content: '';
    font-family: 'Fa solid 900', sans-serif;
    font-size: 11px;
    position: relative;
    margin-left: -12px;
    z-index: 1;
}

.select-field-6 {
    z-index: 2;
}

.mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-bottom: 0 !important;
}

@media only screen and (min-width: 650px) {
    .mCSB_scrollTools_horizontal {
        display: none !important;
    }
}

.profile_menu .mCSB_scrollTools_horizontal {
    display: block !important;
}

@media only screen and (max-width: 650px) {
    .mCSB_horizontal.mCSB_inside > .mCSB_container {
        margin-bottom: 12px !important;
    }

    .profile_menu .mCSB_horizontal.mCSB_inside > .mCSB_container {
        margin-bottom: 12px !important;
    }
}

@media screen and (max-width: 620px) {
    .real_map {
        height: 49vw !important;
    }
}

@media screen and (max-width: 450px) {
    .real_map {
        height: 47vw !important;
    }
}

.grid-2 .table_cont-2 {
    margin-bottom: 0 !important;
}

.tp_trigger:hover .tp_content {
    display: block;
    visibility: visible
}

body {
    min-width: 0 !important;
}

.main-content {
    min-height: calc(100vh - (65.5px + 88px));
}

@media screen and (max-width: 1330px) {
    .div-block-285._4-items {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 767px) {
    .div-block-285._4-items {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 1625px) {
    .profile_menu .mCSB_scrollTools_horizontal {
        display: none !important;
    }
}

.profile_menu .mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-bottom: 12px !important;
}

.download-report-section .export_to_csv a {
    color: #FFFFFF;
    text-decoration: none;
}


.tabs-menu {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: #282c34;
    border-radius: 8px;
    width: 100%;
    margin-bottom: 12px;
    padding: 8px;
    display: flex;
}

.invite-tab-link {
    border: 1px solid transparent;
    background-color: transparent !important;
    color: white !important;
    text-align: center !important;
    border-radius: 7px;
    width: 100%;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-size: 13px;
}

.invite-tab-link:hover {
    border: 1px solid #527bcd;
}

.invite-tab-link.w--current {
    background-color: #527bcd !important;
}

.tabs {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.tabs-2 {
    width: 100%;
}

.tabs-menu-2 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    width: 100%;
    display: flex;
}


.invite-mode-tab-link {
    grid-column-gap: 7px;
    grid-row-gap: 7px;
    color: white !important;
    background-color: #282c34 !important;
    border-radius: 8px !important;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px 0 !important;
    display: flex !important;
}

.invite-mode-tab-link.w--current {
    background-color: #527bcd !important;
}

.flex-block-13 {
    border: 1px solid var(--white);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
}

.flex-block-13.active {
    background-color: var(--white);
}

.image-142 {
    filter: invert();
    width: 20px;
}

.image-142.active {
    filter: none;
}

.heading-5 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 300;
}

.tabs-content {
    margin-top: 15px;
}

.invite-tab-link.w--current {
    background-color: #527bcd;
}

.invite-tab-link:hover {
    border: 1px solid #527bcd;
}

.div-block-228.less-pad {
    padding-top: 5px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.tabs-content {
    margin-top: 15px;
}

.tabs-content {

    padding-left: 0;
    padding-right: 0;

}

.flex-block-13 {
    border: 1px solid white;
    border-radius: 50%;
    justify-content: center;
    align-items: center !important;
    width: 40px;
    height: 40px;
}
