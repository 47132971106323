.pathway-content-grid {
    grid-template-rows: minmax(80px, 80px)!important;
    grid-auto-rows: minmax(80px, 80px) !important;
}
.af-view .tc._15{
    width: 15%;
}
.edit-pathway-btn{
    justify-content: center;
}
.af-view .div-block-207.first.active.follow-user.redeem-btn.mg-l16.edit-pathway-btn{
    margin-left: unset;
}