input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}
.license-notification-block{
    padding: 10px;
    background-color: green;
    border-radius: 6px;
}
.license-notification-block.error{
    background-color: #e45757;
}
