.user-group-list-popup .flex-block-21{
    padding: 0px;
}
.user-group-list-popup .table-title{
    color: #FFF;
}
.user-group-list-popup .table-text{
    overflow: auto;
}
.user-group-list-popup .table-block._55{
    width: 55%;
    max-width: none;
}