.user-row {
    background-color: transparent;
    width: 100%;
    text-align: left;
}


.autocomplete-users {
    top: 97px;
    padding-bottom: 50px;
    z-index: 999;

}


.af-view .custom {
    position: absolute;
    top: 50px;
}

.dynamic-input {
    padding-left: 50px !important;
}
