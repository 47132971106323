.af-view .hf_popup.license-assignment-list-popup-container .pagination-container{
    width: 100%;
}
.af-view .hf_popup.license-assignment-list-popup-container .pagination li:not(.previous,.next){
    display: none!important;
}
.af-view .hf_popup .assignment-title{
    max-width: 300px;
}
.af-view .hf_popup.license-assignment-list-popup-container{
    min-width: 550px;
}